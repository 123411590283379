import React from "react"
// import Layout from "../components/layout"
import Helmet from "react-helmet"
import SEO from "../helpers/seo"

const NotFoundPage = () => (
  <React.Fragment>
    <Helmet>
      <body className="404-page" />
    </Helmet>
    <SEO title="404: Not found" />
    <div className="content-wrapper">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </React.Fragment>
)

export default NotFoundPage
